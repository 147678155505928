// 引入babel-polyfill，解决兼容性问题
import 'babel-polyfill'

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入视频播放器css样式
import "video.js/dist/video-js.css";

// 引入echarts
import * as echarts from "echarts";

Vue.prototype.$echarts = function (el) {
  return echarts.init(el, null, { renderer: "svg" });
};

Vue.prototype.$Echarts = echarts;

// 引入屏幕适配插件
import "amfe-flexible/index.js";

// 引入格式化css样式
import "reset.css";

// 按需引入Element-ui
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import "./plugins/element.js";

// 按需引入DataV
import "./plugins/dataV.js";

// 导入 NProgress 进度条 依赖插件 及样式文件
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// 引入axios
import axios from "axios";

Vue.use(ElementUI);
// 配置请求的根路径

axios.defaults.baseURL = "https://ffjzhfcapi.ffjzhfc.com/jeecg-boot/apiWeb/";
// axios.defaults.baseURL = "https://ffjzhfc.ffjzhfc.com/jeecg-boot/apiWeb/";
// axios.defaults.baseURL = "http://localhost:8090/jeecg-boot/apiWeb/";
// axios.defaults.baseURL = "https://xiaoy.zijiancode.cn/jeecg-boot/apiWeb/";

// 配置请求拦截器 在请求头中使用添加 Authorization字段
axios.interceptors.request.use((config) => {
  config.headers = { "X-Access-Token": window.localStorage.getItem("token") };

  // 在请求拦截器中调用NProgress.star()
  NProgress.start();

  // 在最后必须 return config
  return config;
});

axios.interceptors.response.use((config) => {
  // 在响应拦截器中调用NProgress.done()
  NProgress.done();

  return config;
});

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
