import Vue from "vue";
import {
  Row,
  Col,
  Message,
  MessageBox,
  Input,
  Pagination,
  Dialog,
  Container,
  Header,
  Main,
  Button,
  Form,
  FormItem,
  Carousel,
  CarouselItem,
  Radio
} from "element-ui";

Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Radio);

// Message 和 Button Input等等不同的是 需要挂载到vue的原型对象中 利用this来调用
Vue.prototype.$message = Message;

// MessageBox 弹窗提示也需要挂载到vue原型对象上
Vue.prototype.$confirm = MessageBox.confirm;
